
















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { DispatchModule, DivisionModule } from '@/store/index';
import { FiltersModel } from '../../models/filters/filters.model';
import DateFilter from './DateFilter/DateFilter.vue';
import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';
import { DispatchType } from '../../models/dispatch.model';
import { ToastHelper } from '@/utils/toast.util';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DateType } from '../../models/filters/date-filter.model';
import { NumberType } from '../../models/filters/number-filter.model';
import moment from 'moment';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import Loading from '@/shared/components/Loading.vue';
import { createDivisionFilter } from '@/shared/services/division/division.helper';

@Component({
  components: {
    DateFilter,
    SelectionFilter,
    Loading
  }
})
export default class MainFilters extends Vue {
  @Watch('division')
  onDivisionChange() {
    this.search();
  }

  loading = false;
  excelData: any;
  routeName = 'export';

  valueType = [
    { value: null, text: 'All' },
    { value: 'IN_TRANSIT', text: 'In transit' },
    { value: 'COMPLETED', text: 'Completed' }
  ];

  selectionTypes = {
    list: [
      { value: NumberType.ContainerNumber, text: 'CONT#' },
      { value: NumberType.OrderNumber, text: 'WO#' },
      { value: NumberType.ReferenceNumber, text: 'REF#' },
      { value: NumberType.PONumber, text: 'PO#' },
      { value: NumberType.BookingNumber, text: 'BK#' },
      { value: NumberType.BillTo, text: 'Bill To' },
      { value: NumberType.PulloutLocation, text: 'P/U' },
      { value: NumberType.DeliveryLocation, text: 'Delivery' },
      { value: NumberType.ReturnLocation, text: 'Return' },
      { value: NumberType.Driver, text: 'DRV ID' }
    ],
    autocompleteList: [
      NumberType.BillTo,
      NumberType.PulloutLocation,
      NumberType.DeliveryLocation,
      NumberType.ReturnLocation,
      NumberType.BookingNumber
    ]
  };

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  typeFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: NumberType.ContainerNumber
  });
  divisionModule = DivisionModule;

  constructor() {
    super();
  }

  get division() {
    return this.divisionModule.division;
  }

  created() {
    this.search();
  }

  async search() {
    this.loading = true;

    try {
      this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
        this.fromFilter.search,
        [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
      );

      this.toFilter.search = moment(this.toFilter.search, [
        BS_DATEPICKER_FORMAT,
        DATE_API_FORMAT
      ])
        .endOf('day')
        .format(DATE_API_FORMAT);

      DispatchModule.setFilters(this.getFilters());

      await DispatchModule.search({
        dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
        category: this.$route.name.replace(`${this.routeName}-`, '')
      });
    } catch (err) {
      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }

    this.loading = false;
  }

  async excel() {
    this.loading = true;

    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    DispatchModule.setFilters(this.getFilters());

    await DispatchModule.excel({
      dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
      category: this.$route.name.replace(`${this.routeName}-`, '')
    });

    this.loading = false;

    if (DispatchModule.excelData) {
      window.open(DispatchModule.excelData.url);
    }
  }

  resetFilters() {
    (this.$refs as any).dateFilter.resetFilter();
    (this.$refs as any).selectionFilter.resetFilter();
    this.fromFilter = new FilterInput({
      condition: FilterConditions.GreaterOrEqual,
      search: moment()
        .subtract(12, 'days')
        .format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.toFilter = new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment().format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.typeFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: NumberType.TrailNumber
    });
    DispatchModule.setFilters(this.getFilters());
  }

  private getFilters(): string {
    return TransformFiltersToJson([
      this.toFilter,
      this.fromFilter,
      this.typeFilter,
      createDivisionFilter()
    ]);
  }
}
